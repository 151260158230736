<template>
  <a-config-provider :locale="locale">
    <div id="app">
        <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import * as commonApi from '@/commonApi';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  mounted() {
    this.getMenuList();
  },
  methods: {
    async getMenuList() {
      const res = await commonApi.GetMenu();
      if (res && res['admin']) {
        window.localStorage.setItem('admin', JSON.stringify(res['admin']))
      }

      if (res.code == '0') {
        this.$store.commit('SET_DYNAMIC_MENU', res.menuList);
      }
    },
  },
};
</script>
<style lang="less">
  @import '~ant-design-vue/dist/antd.less'; // 引入官方提供的 less 样式入口文件
  .ant-menu::-webkit-scrollbar {
    display: none;
  }
  .ant-btn{
    border-radius: 20px;
  }
</style>
