/*
 * @Author: your name
 * @Date: 2021-03-01 17:58:33
 * @LastEditTime: 2021-03-02 14:08:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \platform-manager\src\router\menu.js
 */
import { baseLayout, emptyLayout } from '@/layout';
import store from '@/store';
import router from './index';

// 系统菜单、路由
const systemRoutes = [
  {
    name: 'index',
    component: baseLayout,
    path: '/',
    redirect: '/system-config/log',
    title: '首页',
    meta: {
      title: '首页',
    },
    children: [],
  },
  {
    name: 'login',
    component: () => import('@/views/login'),
    path: '/login',
    title: '登录',
    meta: {
      title: '登录',
    },
  }
];

// 自定义菜单、路由
const customRoutes = [
  /******************************** 平台菜单管理 ********************************/
  {
    name: '菜单管理',
    path: '/system-config/platform-menu',
    component: () => import('@/views/system-config/platform-menu'),
    title: '菜单管理',
    meta: {
      isShow: false,
      title: '菜单管理',
    },
  },
];

// 添加动态路由
store.subscribe((mutation, state) => {
  if (mutation.type == 'SET_DYNAMIC_MENU') {
    store.commit('SET_MENU', []);
    const newRoutes = mutation.payload;
    const dynamicRoutes = relationComponentsToRoute(newRoutes);
    const tempData = customRoutes.concat(dynamicRoutes);
    systemRoutes[0].children = tempData;
    store.commit('SET_MENU', systemRoutes[0].children);

    // router.addRoutes(systemRoutes[0].children);
    router.addRoutes(dynamicRoutes);
  }
});

// 动态路由关联组件
function relationComponentsToRoute(route) {
  route.forEach(item => {
    if (typeof item.component === 'undefined') {
      if (item.children) {
        item.component = baseLayout;
      } else {
        item.component = () => import(`../views${item.path}/index.vue`); // 这里 import 路径不能用变量
        // item.component = resolve => require([`../views${item.path}/index.vue`], resolve);
      }
      item.meta = {
        title: item.title,
      };
    }
    if (typeof item.children !== 'undefined' && item.children.length > 0) {
      item = relationComponentsToRoute(item.children);
    }
  });
  return route;
}

// 添加自定义路由
systemRoutes[0].children = customRoutes;
store.commit('SET_MENU', systemRoutes[0].children);

export default systemRoutes;
