var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"}},[_c('div',[_c('a-upload',{attrs:{"list-type":"picture-card","show-upload-list":false,"before-upload":_vm.beforeUpload,"customRequest":(e) => {
          _vm.uploadCustomRequest(e, 'preview');
        }}},[_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("点击上传")])],1)])],1),_vm._l((_vm.file_url),function(item,index){return _c('div',{key:index,staticClass:"up-img-box"},[(
        item.indexOf('.png') != -1 ||
        item.indexOf('.jpg') != -1 ||
        item.indexOf('.jpeg') != -1
      )?_c('span',[_c('img',{staticClass:"up-img-item",attrs:{"src":item + '?x-oss-process=image/resize,m_fixed,h_400,w_400'}}),_c('a-icon',{staticClass:"up-img-remove",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.deleteFile(index)}}})],1):(item.indexOf('.mp4') != -1)?_c('span',[_c('video',{staticStyle:{"width":"110px","height":"103px","border":"1px dashed #d9d9d9"},attrs:{"src":item,"controls":""}}),_c('a-icon',{staticClass:"up-img-remove",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.deleteFile(index)}}})],1):_c('span',[_c('div',{staticStyle:{"width":"110px","height":"103px","overflow":"hidden"}},[_c('a-icon',{staticStyle:{"font-size":"100px"},attrs:{"type":"file-word","title":item}})],1),_c('a-icon',{staticClass:"up-img-remove",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.deleteFile(index)}}})],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }