<!--
  文件上传到阿里云OSS
-->
<template>
  <div style="display: flex">
    <div>
      <a-upload
        list-type="picture-card"
        :show-upload-list="false"
        :before-upload="beforeUpload"
        :customRequest="
          (e) => {
            uploadCustomRequest(e, 'preview');
          }
        "
      >
        <div>
          <a-icon type="plus" />
          <div class="ant-upload-text">点击上传</div>
        </div>
      </a-upload>
    </div>
    <div class="up-img-box" v-for="(item, index) in file_url" :key="index">
      <span
        v-if="
          item.indexOf('.png') != -1 ||
          item.indexOf('.jpg') != -1 ||
          item.indexOf('.jpeg') != -1
        "
      >
        <img
          :src="item + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
          class="up-img-item"
        />
        <a-icon
          type="close-circle"
          class="up-img-remove"
          @click="deleteFile(index)"
        />
      </span>
      <span v-else-if="item.indexOf('.mp4') != -1">
        <video
          :src="item"
          controls
          style="width: 110px; height: 103px; border: 1px dashed #d9d9d9"
        ></video>
        <a-icon
          type="close-circle"
          class="up-img-remove"
          @click="deleteFile(index)"
        />
      </span>
      <span v-else>
        <div style="width: 110px; height: 103px; overflow: hidden">
          <a-icon style="font-size: 100px" type="file-word" :title="item" />
        </div>
        <a-icon
          type="close-circle"
          class="up-img-remove"
          @click="deleteFile(index)"
        />
      </span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { GetSign } from "@/commonApi/index";
export default {
  name: "custom-file",
  props: {
    img_url: [String, Array],
    type: {
      type: String,
      default: "multiple",
    },
  },

  mounted() {},
  watch: {
    img_url: {
      handler: function (e) {
        if (this.type == "multiple") {
          console.log(e);
          this.file_url = e == "" || e == null ? [] : e.split(",");
        } else if (this.type == "single") {
          this.file_url = e && e.length ? e.split(",") : [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      file_url: [],
    };
  },
  methods: {
    deleteFile(index) {
      let temp = this.file_url.splice(index, 1);
      if (this.type == "multiple") {
        this.$emit("changeCustomFile", this.file_url);
      } else if (this.type == "single") {
        this.$emit("changeCustomFile", this.file_url.join(","));
      }
    },
    beforeUpload(file) {
      // if (this.uploadFlag == 'preview') {
      //   const isJpgOrPng =
      //     file.type === "image/jpeg" || file.type === "image/png";
      //   if (!isJpgOrPng) {
      //     this.$message.error("请上传图片");
      //     return false;
      //   }
      // }
    },
    /**
     * 生成oss文件key
     */
    osskey(len) {
      len = len || 32;
      var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      var maxPos = chars.length;
      var pwd = "";
      for (var i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    async uploadCustomRequest(e, type) {
      this.uploadFlag = type;
      try {
        // 获取 oss 签名
        const sign = await GetSign();
        const { file } = e;
        let fileUrl = file["name"];
        let t = fileUrl.lastIndexOf(".");
        let fileType = fileUrl.substr(t);
        let fileName = this.osskey(15);
        let key = fileName + fileType;
        const { accessid, dir, expire, host, policy, signature } = sign;
        let form = new FormData();
        form.append("key", `${dir}${key}`);
        form.append("OSSAccessKeyId", accessid);
        form.append("policy", policy);
        form.append("Signature", signature);
        form.append("file", file);
        form.append("success_action_status", "200");
        const uploadRes = await axios.post(host, form, {
          "Content-Type": " multipart/form-data",
        });
        if (uploadRes.status == 200) {
          if (this.type == "multiple") {
            console.log(this.file_url);
            this.file_url.push(host + "/" + dir + key);
            this.$emit("changeCustomFile", this.file_url);
          } else if (this.type == "single") {
            this.file_url = [host + "/" + dir + key];
            this.$emit("changeCustomFile", host + "/" + dir + key);
          }
        }
      } catch (error) {
        console.log(error);
        this.$message.error("上传出错，请联系管理员");
      }
    },
  },
};
</script>

<style scoped>
.up-img-box {
  position: relative;
  cursor: pointer;
  width: 120px;
}
.up-img-item {
  width: 110px;
  height: 103px;
  border: 1px dashed #d9d9d9;
}
.up-img-remove {
  position: absolute;
  right: 0px;
  top: -10px;
  color: #ff0000 !important;
  font-size: 24px;
}
</style>
