import axios from 'axios';
import router from '@/router';
import { message } from "ant-design-vue";
import vm from '../main';


const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 1000*60*60*2, // 请求超时时间 2小时
});

/**
 * 状态码判断 具体根据当前后台返回业务来定
 * @param {*请求状态码} status
 * @param {*错误信息} err
 */
const errorHandle = (status, err) => {
  switch (status) {
    case 401:
      message.error({ content: '你还未登录' });
      break;
    case 404:
      message.error({ content: '请求路径不存在' });
      break;
    case 500:
      message.error({ content: '系统繁忙，请稍后重试' });
      break;
    default:
      message.error({ content: '网络错误', type: 'error' });
  }
};

service.interceptors.request.use(request => {
  if (request['method'] == 'post') {
    vm.$store.commit('SET_REQUEST_LOADING', true)
  }
  if (!request.headers.login_code) {
    request.headers.login_code = window.login_code ? window.login_code : localStorage.getItem('login_code');
    request.headers.platform_code = window.localStorage.getItem("platformCode");
    // window.localStorage.getItem("platformCode");
    //company  saas labor_team
  }
  if (request.data) {
    const data = typeof request.data === 'string' ? JSON.parse(request.data) : request.data;
    for (const dataKey in data) {
      if (dataKey === 'img_url') {
        data.img_url_scc = data.img_url
      }
    }
  }
  return Promise.resolve(request);
});
/**
 * 响应拦截
 */
service.interceptors.response.use(
  response => {
    if (response.config.method == 'post') {
      setTimeout(() => {
        vm.$store.commit('SET_REQUEST_LOADING', false)
      }, 2000)
    }
    if (response.status === 200) {
      if (typeof response.data.code == 'undefined') return Promise.resolve(response.data);
      if (response.data.code == '0' || response.data.code == '30000') {
        return Promise.resolve(response.data);
      }
      switch (response.data.code) {
        case '1':
          return Promise.resolve(response.data);
        case '-1':
          router.push({
            path: '/login',
          });
          break;
        case '2':
          message.error({ content: response.data.message });
          return Promise.reject(response.data);
        default:
          message.error({ content: response.data.message });
          return Promise.reject(response.data);
      }
    } else {
      return Promise.reject(response.data);
    }
  },
  error => {
    vm.$store.commit('SET_REQUEST_LOADING', false);
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在 2xx 的范围
      errorHandle(response.status, response.data.msg);
      return Promise.reject(response.data);
    } else {
      // 处理断网的情况
      if (!window.navigator.onLine) {
        message.error({ content: '你的网络已断开，请检查网络' });
      } else {
        message.error({ content: '请求失败,请检查' });
      }
      return Promise.reject(error);
    }
  }
);

export default service;
