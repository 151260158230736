
<template>
  <div>
    <div class="tabs-list">
      <div class="tabs-item" @click="changeCurrent(1)" :class="current == 1 ? 'active' : ''">中文简体</div>
      <a-divider type="vertical" class="divider-cu"/>
      <div class="tabs-item" @click="changeCurrent(2)" :class="current == 2 ? 'active' : ''">中文繁体</div>
      <a-divider type="vertical" class="divider-cu"/>
      <div class="tabs-item" @click="changeCurrent(3)" :class="current == 3 ? 'active' : ''">英文</div>
    </div>
    <div>
      <div class="scc" v-show="current == 1">
        <slot name="scc"></slot>
      </div>
      <div class="tcc" v-show="current == 2">
        <slot name="tcc"></slot>
      </div>
      <div class="en" v-show="current == 3">
        <slot name="en"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "custom-tabs",
    props:{},
    mounted() {},
    watch: {},
    data() {
      return {
        current: 1
      }
    },
    methods: {
      changeCurrent(index) {
        this.current = index;
      }
    }
  }
</script>

<style scoped lang="less">
.tabs-list {
  display: flex;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
.tabs-item{
  width: 100px;
  /*border-right: 1px solid #eee;*/
  text-align: center;
  cursor: pointer;
  padding: 10px;
}
.tabs-item.active{
  border-bottom: 2px solid @primary-color;
  color: @primary-color;
  font-weight: bold;
}
.divider-cu {
  top: 0.94rem;
}
</style>
