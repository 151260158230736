<!--
  在表格中显示小图片，带图片预览
-->
<template>
  <div style="display: flex">
    <div class="small-up-img-box" v-for="(item, index) in file_url" :key="index">
        <span @click.stop.prevent="imagePreview(item)">
          <img
            :src="item + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
            class="small-up-img-item"
          />
        </span>
    </div>
    <a-modal
      title="图片预览"
      :footer="null"
      @cancel="imgPreviewCancel"
      :visible="imgDialog">
      <img :src="preview_url" alt="" style="width: 100%;height: 100%;">
    </a-modal>
  </div>

</template>

<script>
  export default {
    name: "custom-file",
    props: {
      img_url: String,
    },
    mounted() {
      this.file_url = this.img_url.length > 0 ? this.img_url.split(',') : [];
    },
    watch: {
      img_url: {
        handler: function (e) {
          this.file_url = e.length > 0 ? e.split(',') : [];
        },
        immediate: true,
      }
    },
    data() {
      return {
        imgDialog: false,
        file_url: '',
        preview_url: ''
      }
    },
    methods: {
      imagePreview(url) {
        this.imgDialog = true;
        this.preview_url = url;
      },
      imgPreviewCancel() {
        this.imgDialog = false;
        this.preview_url = '';
      }
    }
  }
</script>

<style scoped>
  .small-up-img-box {
    position: relative;
    cursor: pointer;
    width: 60px
  }
  .small-up-img-item {
    width: 50px;
    height: 50px;
  }
</style>
