import request from '@/utils/request';

/**
 * 修改密码
 */
export function ChangePwd(params) {
  return request({
    url: '/admin/main/changePwd',
    method: 'POST',
    data: params,
  });
}
