
<template>
  <div>
    <span class="view-img" v-if="data == null || data == ''" >暂无文件</span>
    <span v-else v-for="(item, index) in data.split(',')" :key="index">
      <div
        class="view-img"
        v-if="
          item.indexOf('.png') != -1 ||
          item.indexOf('.jpg') != -1 ||
          item.indexOf('.PNG') != -1 ||
          item.indexOf('.JPG') != -1 ||
          item.indexOf('.jpeg') != -1 ||
          item.indexOf('.JPGE') != -1
        "
      >
        <img
          :src="item + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
          @click="
            imgvisible = true;
            viewImgData = item;
          "
          alt=""
        />
      </div>

      <a v-else class="view-img" :href="item" target="_blank" style="text-align:center">
        <a-icon style="font-size: 60px;color: #999;" type="file-text" />
      </a>
    </span>
    <a-modal
      v-model="imgvisible"
      title="预览"
      ok-text="确认"
      @ok="hideModal"
      :footer="null"
    >
      <img
        style="width: 100%"
        :src="viewImgData + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
export default {
  props: ["data"],
  watch: {},
  data() {
    return {
      imgvisible: false,
      viewImgData: "",
    };
  },
  methods: {
    hideModal() {
      this.imgvisible = false;
    },
  },
};
</script>

<style scoped lang="less">
.view-img {
  width: 90px;
  height: 60px;
  margin: 3px 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  border-radius: 4px;
}
img {
  max-width: 100%;
}
</style>
