var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.data == null || _vm.data == '')?_c('span',{staticClass:"view-img"},[_vm._v("暂无文件")]):_vm._l((_vm.data.split(',')),function(item,index){return _c('span',{key:index},[(
        item.indexOf('.png') != -1 ||
        item.indexOf('.jpg') != -1 ||
        item.indexOf('.PNG') != -1 ||
        item.indexOf('.JPG') != -1 ||
        item.indexOf('.jpeg') != -1 ||
        item.indexOf('.JPGE') != -1
      )?_c('div',{staticClass:"view-img"},[_c('img',{attrs:{"src":item + '?x-oss-process=image/resize,m_fixed,h_400,w_400',"alt":""},on:{"click":function($event){_vm.imgvisible = true;
          _vm.viewImgData = item;}}})]):_c('a',{staticClass:"view-img",staticStyle:{"text-align":"center"},attrs:{"href":item,"target":"_blank"}},[_c('a-icon',{staticStyle:{"font-size":"60px","color":"#999"},attrs:{"type":"file-text"}})],1)])}),_c('a-modal',{attrs:{"title":"预览","ok-text":"确认","footer":null},on:{"ok":_vm.hideModal},model:{value:(_vm.imgvisible),callback:function ($$v) {_vm.imgvisible=$$v},expression:"imgvisible"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.viewImgData + '?x-oss-process=image/resize,m_fixed,h_400,w_400',"alt":""}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }