import request from '@/utils/request';

export function GetMenu() {
  return request({
    url: '/admin/main/getDetail',
    method: 'GET',
  });
}
// 分类

export function CategoryList() {
  return request({
    url: '/admin/goods/goodClassify/list',
    method: 'GET',
  });
}
export function CategorySave(data) {
  return request({
    url: '/admin/goods/goodClassify/save',
    method: 'POST',
    data,
  });
}
// 标签

export function TagList() {
  return request({
    url: '/admin/goods/goodLabel/list',
    method: 'GET',
  });
}
export function TagSave(data) {
  return request({
    url: '/admin/goods/goodLabel/save',
    method: 'POST',
    data,
  });
}
// 添加规格分类
export function SkuCategorySave(data) {
  return request({
    url: '/admin/goods/goodSpecsTemplateType/save',
    method: 'POST',
    data,
  });
}

// OSS 签名
export function GetSign() {
  return request({
    url: '/base/oss/getSign',
    method: 'GET',
  });
}

// 省市区
export function GetCPA(params) {
  return request({
    url: `/store/getCity`,
    method: 'GET',
    params,
  });
}

// 门店列表
export function GetStore(params) {
  return request({
    url: `/admin/salesroom/info/getSalesroomList`,
    method: 'GET',
    params,
  });
}

// 自定义页列表
export function PageList(params) {
  return request({
    url: '/admin/renovation/customizePage/page',
    method: 'GET',
    params,
  });
}
// 自定义页列表
export function Logout(params) {
  return request({
    url: '/admin/login/exit/',
    method: 'GET',
    params,
  });
}

export function GetDiary(params) {
  return request({
    url: '/admin/lanfenPage/info/page',
    method: 'GET',
    params,
  });
}

export function getMMhh(str) {
  let date = new Date(str);
}

// 导出
export function exportData(url) {
  let date = new Date(str);
}


