<!--
  ueditor 上传文件到阿里云OSS
-->
<template>
  <div>
    <vue-ueditor-wrap v-model="ueditorContent" :config="config" @beforeInit="addCustomButtom"></vue-ueditor-wrap>
    <a-modal
      title="图片/视频上传"
      :visible="dialogVisble"
      :footer="null"
      @cancel="handlerCancel">
      <div class="upload-box">
        <a-upload
          name="cuImage"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :before-upload="beforeUploadImage"
          :customRequest="e => uploadImageRequest(e)">
          <div>
            <a-icon type="picture" style="font-size: 24px" />
            <div class="ant-upload-text">
              <a-button type="link">上传图片</a-button>
            </div>
          </div>
        </a-upload>
        <a-upload
          name="cuVideo"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :before-upload="beforeUploadVideo"
          :customRequest="e => uploadVideoRequest(e)">
          <div>
            <a-icon type="youtube" style="font-size: 24px" />
            <div class="ant-upload-text"></div>
            <a-button type="link">上传视频</a-button>
          </div>
        </a-upload>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import VueUeditorWrap from 'vue-ueditor-wrap'
  import {GetSign} from '@/commonApi/index'
  import axios from "axios";
  const key = 'updatable';
  export default {
    name: "custom-editor",
    components:{
      VueUeditorWrap
    },
    props: {
      content: String,
    },
    mounted() {
      this.ueditorContent = this.content
    },
    watch: {
      content: {
        handler: function (e) {
          this.ueditorContent = e;
        },
        immediate: true,
      },
      ueditorContent(e) {
        this.$emit('update:content', this.ueditorContent);

      },
    },
    data() {
      return {
        config: {
          autoHeightEnabled: false,
          initialFrameHeight: 600,
          initialFrameWidth: '100%',
          UEDITOR_HOME_URL: '/UEditor/'
        },
        ueditorContent: '',
        cuEditor: null,
        dialogVisble: false

      }
    },
    methods: {
      handlerCancel() {
        this.dialogVisble = false;
      },
      // ueditorContent() {
      //   this.$emit('update:content', this.ueditorContent);
      // },
      addCustomButtom() {
        const _this = this;
        window.UE.registerUI(
          'test-button',
          function(editor, uiName) {
            _this.cuEditor = editor;
            // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
            editor.registerCommand(uiName, {
              execCommand: function () {
                editor.execCommand('inserthtml', `<span>这是一段由自定义按钮添加的文字</span>`)
              }
            })

            // 创建一个 button
            var btn = new window.UE.ui.Button({
              // 按钮的名字
              name: uiName,
              // 提示
              title: '上传图片',
              // 需要添加的额外样式，可指定 icon 图标，图标路径参考常见问题 2
              cssRules: "background-image: url('https://jintufile.oss-cn-huhehaote.aliyuncs.com/jintufile/ueimg.png') !important;background-size: cover;",
              // 点击时执行的命令
              onclick: function() {
                _this.dialogVisble = true;
                // 这里可以不用执行命令，做你自己的操作也可
              },
            });

            // 当点到编辑内容上时，按钮要做的状态反射
            editor.addListener('selectionchange', function() {
              var state = editor.queryCommandState(uiName);
              if (state === -1) {
                btn.setDisabled(true);
                btn.setChecked(false);
              } else {
                btn.setDisabled(false);
                btn.setChecked(state);
              }
            });

            // 因为你是添加 button，所以需要返回这个 button
            return btn;
          },
          0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
        );
      },
      /**
       * 生成oss文件key
       */
      osskey(len) {
        len = len || 32;
        var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
        var maxPos = chars.length;
        var pwd = "";
        for (var i = 0; i < len; i++) {
          pwd += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
      },
      
      // 图片上传处理函数
      async uploadImageRequest(e, type) {
        console.log(e)
        try {
          // 获取 oss 签名
          const sign = await GetSign();
          console.log(sign);
          const { file } = e;
          let fileUrl = file["name"];
          let t = fileUrl.lastIndexOf(".");
          let fileType = fileUrl.substr(t);
          let fileName = this.osskey(15);
          let key = fileName + fileType;
          const { accessid, dir, expire, host, policy, signature } = sign;
          let form = new FormData();
          form.append("key", `${dir}${key}`);
          form.append("OSSAccessKeyId", accessid);
          form.append("policy", policy);
          form.append("Signature", signature);
          form.append("file", file);
          form.append("success_action_status", "200");
          const uploadRes = await axios.post(host, form, {
            "Content-Type": " multipart/form-data",
          });
          if (uploadRes.status == 200) {
            let imgUrl = host + "/" + dir + key;
            this.cuEditor.execCommand('inserthtml', `<img style="width: 100%" src=${imgUrl} />`);
            this.$message.success({content: "上传成功，已添加到编辑器中", key});
          }
        } catch (error) {
          this.$message.error({content: "上传出错，请联系管理员", key});
        }
      },
      beforeUploadImage(file) {
        this.$message.loading({ content: '正在上传中...', key});
        const isJpgOrPng =
          file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
          this.$message.error({content: "请上传.jpg,.png格式的图片", key});
          return false;
        }
      },
      // 视频上传处理函数
      async uploadVideoRequest(e, type) {
        try {
          // 获取 oss 签名
          const sign = await GetSign();
          const { file } = e;
          let fileUrl = file["name"];
          let t = fileUrl.lastIndexOf(".");
          let fileType = fileUrl.substr(t);
          let fileName = this.osskey(15);
          let key = fileName + fileType;
          const { accessid, dir, expire, host, policy, signature } = sign;
          let form = new FormData();
          form.append("key", `${dir}${key}`);
          form.append("OSSAccessKeyId", accessid);
          form.append("policy", policy);
          form.append("Signature", signature);
          form.append("file", file);
          form.append("success_action_status", "200");
          const uploadRes = await axios.post(host, form, {
            "Content-Type": " multipart/form-data",
          });
          if (uploadRes.status == 200) {
            let videoUrl = host + "/" + dir + key;
            this.cuEditor.execCommand('inserthtml', `<video style="width: 100%" src="${videoUrl}" controls="controls" />`);
            this.$message.success({content: "上传成功，已添加到编辑器中", key});
          }
        } catch (error) {
          this.$message.error({content: "上传出错，请联系管理员", key});
        }
      },
      beforeUploadVideo(file) {
        this.$message.loading({ content: '正在上传中...', key});
        const isJpgOrPng =
          file.type === "video/mp4";
        if (!isJpgOrPng) {
          this.$message.error({content: "请上传.mp4的视频", key});
          return false;
        }
      },
    }
  }
</script>

<style scoped>
  .upload-box {
    display: flex;
    padding-left: 80px;
  }
</style>
