// 常用字段维护
import { getActiveKey } from "ant-design-vue/lib/vc-menu/SubPopupMenu";

let fields = [
  "id",
  "tid",
  "num_code",
  "sort",
  "group_id",
  "img_urls",
  "country_id",
  "province_id",
  "city_id",
  "area_id",
  "mobile",
  "afternoon_end_time",
  "afternoon_start_time",
  "morning_end_time",
  "morning_start_time",
  "login_account",
  "login_pwd",
  "types",
  "img_url",
  "order_quantity",
  "business_hours",
  "type_ids",
  "clinic_id",
  "project_ids",
  "term_of_validity",
  "price_rmb",
  "price_hk",
  "implementation_plan",
  "price_cost",
  "specialist_ids",
  "registration_fee_gb",
  "registration_fee_rmb",
  "head_img_url",
  "reservation_num",
];
let commonFilterFields = ["id", "sort", "create_time"];
let moduleFilterFields = [
  // {
  //   module: "arAddEdit", // 资讯管理,添加编辑
  //   fields: ["tid"],
  //   module: "activity", // 优惠券管理
  //   fields: ["over_time", "coupon_ids"],
  // },
  {
    module: "feedbackType", // app版本管理
    fields: [],
  },
  {
    module: "appVersion", // app版本管理
    fields: ["internal_version", 'external_version', 'type', 'is_forcibly', 'android_down_url'],
  },
  {
    module: "arAddEdit", // 资讯管理,添加编辑
    fields: ["tid"],
  },
  {
    module: "cuActiveAddEdit", // 优惠券活动,添加编辑
    fields: [
      "over_time",
      "coupon_ids",
    ],
  },
  {
    module: "cuAddEdit", // 优惠券,添加编辑
    fields: [
      "price_rmb",
      "price_gb",
      "type_ids",
      "good_ids",
      "validity_type",
      "how_days",
      "start_time",
      "end_time",
    ],
  },
  {
    module: "adAddEdit", // 广告管理,添加编辑
    fields: ["name", "position", "return_json"],
  },
  {
    module: "agAddEdit", // 协议管理,列表
    fields: ["num_code"],
  },
  {
    module: "ouList", // 诊所,列表
    fields: [
      "country_id",
      "province_id",
      "city_id",
      "area_id",
      "mobile",
      "afternoon_end_time",
      "afternoon_start_time",
      "morning_end_time",
      "morning_start_time",
      "login_account",
      "login_pwd",
      "types",
      "img_urls",
      "order_quantity",
      "business_hours",
    ],
  },
  {
    module: "peType", // 健康体检,分类
    fields: [],
  },
  {
    module: "peGroup", // 健康体检,基础项目分组
    fields: ["country_id"],
  },
  {
    module: "peCustomGroup", // 健康体检,自定义分组
    fields: [],
  },
  {
    module: "peProject", // 健康体检,基础项目
    fields: ["group_id"],
  },
  {
    module: "peAddEdit", // 健康体检,产品
    fields: [
      "type_ids",
      "price_rmb",
      "price_hk",
      "clinic_id",
      "term_of_validity",
      "project_ids",
      "price_cost",
    ],
  },
  {
    module: "vaType", // 预约疫苗,分类
    fields: [],
  },
  {
    module: "vaGroup", // 预约疫苗,基础项目分组
    fields: [],
  },
  {
    module: "vaCustomGroup", // 预约疫苗,自定义分组
    fields: [],
  },
  {
    module: "vaProject", // 预约疫苗,基础项目
    fields: ["group_id", "implementation_plan"],
  },
  {
    module: "vaAddEdit", // 预约疫苗,产品
    fields: [
      "type_ids",
      "price",
      "price_cost",
      "medical_price_rmb",
      "medical_price_hb",
      "commission",
      "clinic_id",
      "term_of_validity",
      "project_ids",

    ],
  },
  {
    module: "doDepartment", // 专科医生,科室
    fields: ["img_url"],
  },
  {
    module: "doList", // 专科医生,列表
    fields: [
      "specialist_ids",
      "registration_fee_rmb",
      "registration_fee_gb",
      "clinic_id",
      "reservation_num",
      "head_img_url",
      "price_cost",
    ],
  },
];

const SCC_Suffix = "_scc";
const TCC_Suffix = "_tcc";
const EN_Suffix = "_en";
export function isField(module, field) {
  let filterFields = moduleFilterFields
    .filter((item) => item.module == module)
    .map((item) => item.fields)[0];
  return [...filterFields, ...commonFilterFields].includes(field);
}

// 子组件Form验证与重置
export const ChildMixin = {
  methods: {
    formValidate: async function(callback) {
      this.$refs.formModel.validate((valid) => {
        callback(valid);
      });
    },
    formReset: function() {
      this.$refs.formModel.resetFields();
    },
  },
};

// 字段组装
export function FieldMixin(field) {
  let scc = JSON.parse(JSON.stringify(field));
  let tcc = JSON.parse(JSON.stringify(field));
  tcc.formModelRules = {};
  let en = JSON.parse(JSON.stringify(field));
  en.formModelRules = {};
  return {
    data: function() {
      return {
        scc: { ...scc },
        tcc: { ...tcc },
        en: { ...en },
      };
    },
    methods: {},
  };
}
function _field(field, suffix) {
  let i = field.indexOf(suffix);
  let f = field.substr(0, i);
  return f;
}
// 字段回显
export function FieldEcho(data) {
  let scc = {},
    tcc = {},
    en = {};
  for (let key in data) {
    if (key.endsWith(SCC_Suffix)) {
      scc[_field(key, SCC_Suffix)] = data[key];
    } else if (key.endsWith(TCC_Suffix)) {
      tcc[_field(key, TCC_Suffix)] = data[key];
    } else if (key.endsWith(EN_Suffix)) {
      en[_field(key, EN_Suffix)] = data[key];
    } else {
      scc[key] = data[key];
    }
  }
  return {
    result: {
      scc: scc,
      tcc: tcc,
      en: en,
    },
  };
}
