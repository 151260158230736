import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';


import request from '@/utils/request';

import 'xe-utils';
// import VXETable from 'vxe-table';
// import 'vxe-table/lib/style.css';

import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';
import VueFroala from 'vue-froala-wysiwyg';

Vue.config.productionTip = false;
import Print from 'vue-print-nb'
Vue.use(Print); //注册
Vue.use(Antd, {size: 'small'});
// Vue.use(VXETable);
Vue.use(VueFroala);

Vue.prototype.$axios = request;

import exportFun from '@/utils/exportFun';
Vue.prototype.$exportFun = exportFun;

// 编辑器组件
import customEditor from '@/components/custom-editor';
Vue.component('custom-editor', customEditor);

// 多个文件显示组件
import customFileList from '@/components/custom-file-list';
Vue.component('custom-file-list', customFileList);

// 小图片在列表中显示的组件
import smallImageList from '@/components/small-image-list';
Vue.component('small-image-list', smallImageList);

// 自定义tabs
import customTabs from '@/components/custom-tabs';
Vue.component('custom-tabs', customTabs);

// vuescroll
import vuescroll from 'vuescroll';
Vue.component('vuescroll', vuescroll);

// 表格内文件显示
import viewFile from '@/components/view-file';
Vue.component('view-file', viewFile);

import moment from 'moment'//导入文件
Vue.prototype.$moment = moment;//赋值使用
moment.locale('zh-cn');//需要汉化

//省市级联
import areaCascader from 'vue-area-linkage';
Vue.use(areaCascader)

// 定义全局导出londing
// import exiconobj from '@/utils/exiconobj';

Vue.prototype.$store = store
// Vue.prototype.EXICON = exiconobj;

import AMap from 'vue-amap';
Vue.use(AMap);

  // 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: '0eece8570ddb723a337f05852b6caeb0',
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Geolocation','AMap.CircleEditor']
});

let windowUrl = window.location.host;
let platformCode = "";
let loginbg = "";
if(windowUrl == "ta-platform.ruanyunlz.com"){
  platformCode="saas"
  loginbg = "https://ta-file-service.oss-cn-beijing.aliyuncs.com/login1.jpg"
}else if(windowUrl == "ta-company.ruanyunlz.com"){
  platformCode="company";
  loginbg = "https://ta-file-service.oss-cn-beijing.aliyuncs.com/login2.jpg"
}else if(windowUrl == "ta-team.ruanyunlz.com"){
  platformCode="labor_team"
  loginbg = "https://ta-file-service.oss-cn-beijing.aliyuncs.com/login3.jpg"
}else{
  platformCode="saas"
  loginbg = "https://ta-file-service.oss-cn-beijing.aliyuncs.com/login1.jpg"
}
window.localStorage.setItem("platformCode",platformCode);
window.localStorage.setItem("loginbg",loginbg);
import {isField} from '@/commonApi/handle-field.js';
Vue.prototype.$m = function(module, scc, tcc, en) {
  let tempSccObj = {}, tempTccObj = {}, tempEnObj = {};
  const oScc = JSON.parse(JSON.stringify(scc));
  const oTcc = JSON.parse(JSON.stringify(tcc));
  const oEn = JSON.parse(JSON.stringify(en));

  for (let oKey in oScc) {
    if (!isField(module, oKey)) {
      tempSccObj[`${oKey}_scc`] = oScc[oKey]
    } else {
      tempSccObj[oKey] = oScc[oKey]
    }
  }
  for (let oKey in oTcc) {
    if (!isField(module, oKey)) {
      tempTccObj[`${oKey}_tcc`] = oTcc[oKey]
    }
  }
  for (let oKey in oEn) {
    if (!isField(module, oKey)) {
      tempEnObj[`${oKey}_en`] = oEn[oKey]
    }
  }
  return {...tempSccObj, ...tempTccObj, ...tempEnObj};
}

// 全局 OSS host 过滤器
Vue.filter('fixHost', val => {
  if (val && val.startsWith('http')) return val;
  return process.env.VUE_APP_OSS_HOST + val;
});

const vm = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

export default vm;
