var exportFun, action, closeConfirm;
import axios from 'axios';
import { message, notification, Modal } from "ant-design-vue";

exportFun = function (content, data, type) {
    let _this = this;
    if (type && type === 'userManager' || type === 'contractLog' || type === 'userLog' || type === 'projectManage' || type === 'personnelList') {
        if (!data.start_time) {
            message.error('请选择开始时间');
            return;
        }
        if (!data.end_time) {
            message.error('请选择结束时间');
            return;
        }

        function daysBetween(date1, date2) {
            const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
            const firstTime = date1.getTime(); // 将日期转换为时间戳
            const secondTime = date2.getTime(); // 同上

            return Math.round(Math.abs((firstTime - secondTime) / oneDay)); // 计算天数并四舍五入
        }
        let day = daysBetween(new Date(data.start_time), new Date(data.end_time))
        if (day > 31) {
            message.error('请选择一个月内时间进行导出');
            return;
        }
    }

    const InfoConfirm = Modal.confirm({
        title: '导出提示',
        content: h => <div>
            导出内容可能包含图片,请选择是否将图片一并导出!
            <a-icon style={{ position: 'absolute', right: '-8px', top: '-8px', fontSize: '24px', borderRadius: '50%', background: '#fff' }}
                onClick={closeConfirm.bind(this)} type="close-circle" />
        </div>,
        closable: true,
        okText: '包含图片',
        cancelText: '不含图片',
        footer: "null",
        onOk() {
            data.img_state = 1
            action(_this, content, data)
        },
        onCancel() {
            data.img_state = ''
            action(_this, content, data)
        },
    });
}
action = function (_this, content, data) {
    console.log(data);
    _this.$store.commit('SET_EXICON', {
        icon: 'loading',
        ed: true
    });
    notification['error']({
        placement: "bottomRight",
        message: '正在执行导出操作',
        description:
            '如导出时间过长,请耐心等待！为了防止给服务器造成过大压力，在当前导出未执行完成前尽量不要有其他操作!',
        duration: 15,
    });
    var aEle = document.createElement("a");// 创建a标签
    axios.get(process.env.VUE_APP_API + content, {
        params: data,
        headers: { 'login_code': window.localStorage.getItem("login_code"), 'platform_code': window.localStorage.getItem("platformCode") }
    })
        .then(function (response) {
            console.log(response);
            if (response.data && response.data.code == '0') {
                _this.$store.commit('SET_EXICON', {
                    icon: 'download',
                    ed: false
                });
                aEle.href = response.data.url;
                aEle.click();// 设置点击事件
            } else {
                message.error({ content: response.data.message });
                _this.$store.commit('SET_EXICON', {
                    icon: 'download',
                    ed: false
                });
            }

        })
        .catch(function (error) {
            console.log(error);
        });
}
closeConfirm = function () {
    Modal.destroyAll();
}

export default exportFun;
